<template>
  <b-container fluid>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    <div v-if="!loading">

      <div v-if="show.table">
        <b-table id="table" striped hover :items="tags" :fields="fields" :filter="filter" @row-clicked="showTag"></b-table>
        <div>
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="showAdd">new</b-btn>
        </div>
      </div>

      <div v-if="show.tag">
        <div>{{ tag.name }}</div>
        <div class="my-4">
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="showTable">back to table</b-btn>
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="showEdit">edit</b-btn>
        </div>
        <div v-if="permissionDelete">
          <b-button v-b-toggle.collapse-1 variant="outline-danger">delete</b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
              <b-btn variant="outline-danger" @click="remove">confirm delete</b-btn>
            </b-card>
          </b-collapse>
        </div>
      </div>

      <div v-if="show.add">
        <b-form-input v-model="tag.name" required class="mb-2"> </b-form-input>
        <div class="my-4">
          <span v-if="!saving">
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="cancelAdd">cancel</b-btn>
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="saveNew" v-if="validate">save</b-btn>
        </span>
        <span v-if="saving">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </span>
        </div>
      </div>

      <div v-if="show.edit">
        <b-form-input v-model="tag.name" required class="mb-2"> </b-form-input>
        <div class="my-4">
          <span v-if="!saving">
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="cancelEdit">cancel</b-btn>
          <b-btn class="mb-4 mr-2" variant="outline-primary" @click="save" v-if="validate">save</b-btn>
        </span>
        <span v-if="saving">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </span>
        </div>
      </div>

    </div>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'

export default {
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.tag.name
    }
  },
  created: function () {
    document.title = "Types - Admin"
    if (!ac.can(this.user.acgroups).readAny('rricpanel').granted) {
      this.$router.push({ name: 'Start' })
    }
    this.$stat.log({ page: 'admin tags', action: 'open page' })
    this.permissionDelete = ac.can(this.user.acgroups).deleteAny('tag').granted
    this.load()
  },
  data () {
    return {
      fields: [
        {
          key: 'name',
          sortable: true
        }
      ],
      filter: '',
      loading: true,
      permissionDelete: false,
      show: {
        add: false,
        edit: false,
        tag: false,
        table: true
      },
      tag: {},
      tagold: null,
      tags: []
    }
  },
  methods: {
    clearShow: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    remove: async function () {
      this.$logger.debug('started delete')
      try {
        let apiName = 'cosmos'
        let path = `/tag/${this.tag.id}`
        await this.$Amplify.API.del(apiName, path)
        this.loading = true
        this.clearShow()
        this.show.table = true
        this.load()
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    load: async function () {
      this.$logger.debug('loading tags started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/tags'
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.tags = response
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    save: async function () {
      this.$logger.debug(`saving tag start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/tag/${this.tag.id}`
        let params = {
          body: this.tag
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.clearShow()
        this.show.tag = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    },
    saveNew: async function () {
      this.$logger.debug(`saving tag start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/tag`
        let params = {
          body: this.tag
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.clearShow()
        this.load()
        this.show.table = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    },
    cancelAdd: function () {
      this.clearShow()
      this.tag = {}
      this.show.table = true
    },
    cancelEdit: function () {
      this.clearShow()
      this.tag = { ...this.tagold }
      this.show.tag = true
    },
    showAdd: function () {
      this.clearShow()
      this.show.add = true
    },
    showEdit: function () {
      this.clearShow()
      this.tagold = { ...this.tag }
      this.show.edit = true
    },
    showTag: function (tag, index, event) {
      this.tag = { ...tag }
      this.clearShow()
      this.show.tag = true
    },
    showTable: function (tag, index, event) {
      this.clearShow()
      this.tag = {}
      this.show.table = true
      this.load()
    }
  }
}
</script>

<style></style>
